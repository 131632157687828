<template>
  <div class="coin-share">
    <panel-title icon="el-icon-coin" title="云币转赠" />

    <panel-content>
      <div slot="filter" class="filter">
        <span class="active">云币转赠</span>
        <span>转赠明细</span>
      </div>

      <div slot="content">
        <el-form label-width="110px" label-position="right" size="medium">
          <el-form-item label="云币额度：">
            <span class="balance">31</span>
          </el-form-item>
          <el-form-item label="转赠账户：">
            <el-input style="width: 50%" />
          </el-form-item>
          <el-form-item label="转赠数量：">
            <el-input style="width: 50%" />
          </el-form-item>

          <el-form-item label="交易密码：">
            <el-input style="width: 50%" />
          </el-form-item>

          <el-form-item>
            <el-button type="primary">确认转赠</el-button>
          </el-form-item>
        </el-form>
      </div>
    </panel-content>
  </div>
</template>

<script>
import PanelContent from "./PanelContent.vue";
import PanelTitle from "./PanelTitle.vue";
export default {
  components: { PanelTitle, PanelContent },
};
</script>

<style lang="scss" scoped>
.coin-share {
  .filter {
    span {
      cursor: pointer;
      display: inline-block;
      padding-right: 20px;
      margin-right: 20px;
      border-right: 1px solid #ccc;

      font-size: 14px;

      line-height: 17px;

      &:hover,
      &:active,
      &.active {
        color: red;
      }
    }

    span:last-child {
      border-right: none;
    }
  }

  .balance {
    font-size: 16px;
    color: red;
    font-weight: bold;
  }
}
</style>